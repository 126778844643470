.enter-y {
  transform: translateY(50px);
  opacity: 0;
  transition: opacity 1s, transform 1.2s cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px)
}
.enter-y-r {
  transform: translateY(-50px);
  opacity: 0;
  transition: opacity 1s, transform 1.2s cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px)
}
.enter-x {
  transform: translateX(50px);
  opacity: 0;
  transition: opacity 1s, transform 1.2s cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: translateX(50px);
  -moz-transform: translateX(50px);
  -ms-transform: translateX(50px);
  -o-transform: translateX(50px)
}
.enter-x-r {
  transform: translateX(-50px);
  opacity: 0;
  transition: opacity 1s, transform 1.2s cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  -o-transform: translateX(-50px)
}
.show .delay-1,
.ready #hero .delay-1 {
  transition-delay: .2s!important
}
.show .delay-2,
.ready #hero .delay-2 {
  transition-delay: .4s!important
}
.show .delay-3,
.ready #hero .delay-3 {
  transition-delay: .6s!important
}
.show .delay-4,
.ready #hero .delay-4 {
  transition-delay: .8s!important
}
.show .delay-5,
.ready #hero .delay-5 {
  transition-delay: 1s!important
}
.show .delay-6,
.ready #hero .delay-6 {
  transition-delay: 1.2s!important
}
.show .delay-7,
.ready #hero .delay-7 {
  transition-delay: 1.4s!important
}
.show .delay-8,
.ready #hero .delay-8 {
  transition-delay: 1.6s!important
}
.show .delay-9,
.ready #hero .delay-9 {
  transition-delay: 1.8s!important
}
.show .delay-10,
.ready #hero .delay-10 {
  transition-delay: 2s!important
}
.show .delay-11,
.ready #hero .delay-11 {
  transition-delay: 2.2s!important
}
.show .delay-12,
.ready #hero .delay-12 {
  transition-delay: 2.4s!important
}
.show .delay-13,
.ready #hero .delay-13 {
  transition-delay: 2.6s!important
}
.show .delay-14,
.ready #hero .delay-14 {
  transition-delay: 2.8s!important
}
.show .delay-15,
.ready #hero .delay-15 {
  transition-delay: 3s!important
}
.show .enter-y,
.show .enter-x,
.show .enter-y-r,
.show .enter-x-r {
  opacity: 1;
  transform: translate(0)
}
